<script setup>
const props = defineProps({
    to: {
        type: [String, Object],
        required: false,
        default: '',
    },
});

const makeFixedUri = computed(() => fixedUri(props.to));

</script>
<template>
    <NuxtLink class="MainLink" :to="makeFixedUri">
        <slot />
    </NuxtLink>
</template>
